import React from 'react';

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <a className="navbar-brand" href="">
          <span>&nbsp; SectorScout</span>
        </a>

        <div className="d-lg-none ms-auto me-4">
          
        </div>

        
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-lg-5 me-lg-auto">
          </ul>

        </div>
      </div>
    </nav>
  );
}

export default Navbar;
