//import React  from 'react';
import { useState } from 'react';
import supabase from '../supabaseClient';

const EmailForm = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {  error } = await supabase
      .from('waitlist')
      .insert([{ email: email }]);

    if (error) {
      alert('Error submitting your email. Try again.');
      console.error('Error:', error);
    } else {
      setSubmitted(true);
    }
  };

  return (
    <form method="get" class="custom-form mt-4 pt-2 mb-lg-0 mb-5"  onSubmit={handleSubmit}>

    <div class="input-group input-group-lg">

        
        <input name="keyword" type="email" class="form-control" id="keyword" placeholder="Enter your email..." aria-label="Search"
        value={email}
        onChange={handleEmailChange}
        required />

        <button type="submit" class="form-control">Join</button>

    </div>
    {submitted && <h5>Thank you for joining!</h5>}

</form>
);
};

export default EmailForm;
