import EmailForm from './EmailForm';
export default function Footer(){
    return (
<footer class="site-footer section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-12 mb-4 pb-2">
                <a class="navbar-brand mb-2" href="index.html">
                    <span>SectorScout</span>
                </a>
                <p>Made with ❤️ in Canada 🍁</p>
                <p>© SectorScout 2024</p>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <p>⚠ All information provided is for educational purposes only and does not constitute investment advice; please consult a financial expert before making investment decisions.</p>
            </div>
        </div>
    </div>
</footer>
    )
}