import EmailForm from './EmailForm';

export default function HeroSection(){
    return (
<section class="hero-section d-flex justify-content-center align-items-center" id="section_1">
<div class="container">
    <div class="row">

        <div class="col-lg-8 col-12 mx-auto">
            <h2 class="text-white text-center"> Fast-track your stocks shortlist!</h2>

            <h6 class="text-center">Take your stock research from days to minutes.</h6>
                        <br></br><br></br><h5 class="text-center">Join our waitlist</h5>
                        <div className="App">
                        <EmailForm />
                        </div>
        </div>

    </div>
</div>
</section>

    )
}