import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
//import React from 'react';
import Navbar from "./components/NavBar.jsx"
import HeroSection from './components/HeroSection.jsx';
import FeaturedSection from './components/FeaturedSection.jsx';
import ExploreSection from './components/ExploreSection.jsx';
import TimelineSection from './components/TimelineSection.jsx';
import Footer from './components/Footer.jsx';
import HomePage from "./HomePage.jsx";
import TOSPage from "./TOSPage.jsx";
import PrivacyPolicy from "./PrivacyPolicy.jsx";




function App() {
  return (
<BrowserRouter>
      <div>
        {/*
        <nav>
           Add navigation links to your pages 
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
        </nav> */}

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tos" element={<TOSPage />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </BrowserRouter>    
  
  );
}

export default App;
