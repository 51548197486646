// HomePage.jsx
import React from "react";
import Navbar from "./components/NavBar.jsx"
import HeroSection from './components/HeroSection.jsx';
import FeaturedSection from './components/FeaturedSection.jsx';
import ExploreSection from './components/ExploreSection.jsx';
import TimelineSection from './components/TimelineSection.jsx';
import Footer from './components/Footer.jsx';

function HomePage() {
  return (
    <>
          <Navbar />
      <HeroSection />
      <FeaturedSection />
      
      <TimelineSection />
      <Footer />
    </>
  );
}

export default HomePage;
