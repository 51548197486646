import research from "../images/research.jpg"
import ranking from "../images/ranking.jpg"
import ai from "../images/AI-feature.jpg"

export default function FeaturedSection(){
    return (
        <section class="featured-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-12 mb-4 mb-lg-0">
                <div class="custom-block bg-white shadow-lg">
                    <div class="d-flex">
                        <div>
                            <h5 class="mb-2">Easy Stock Research</h5>
                            <p class="mb-0">Enhance your stock picks with SectorScout! Just enter your favorites and our software handles the analysis for you.</p>
                        </div>
                    </div>
                    <img src={research} class="custom-block-image img-fluid" alt="Easy Stock Research" />
                </div>
            </div>
            <div class="col-lg-4 col-12 mb-4 mb-lg-0">
                <div class="custom-block bg-white shadow-lg">
                    <div class="d-flex">
                        <div>
                            <h5 class="mb-2">Ranking</h5>
                            <p class="mb-0">We assess key metrics for value stocks, ranking them within their sectors to quickly highlight top choices.</p>
                        </div>
                    </div>
                    <img src={ranking} class="custom-block-image img-fluid" alt="Stock Ranking" />
                </div>
            </div>
            <div class="col-lg-4 col-12 mb-4 mb-lg-0">
                <div class="custom-block bg-white shadow-lg">
                    <div class="d-flex">
                        <div>
                            <h5 class="mb-2">Research on your fingertips</h5>
                            <p class="mb-0">Save time on your research from browsing the internet. Ask our Scout AI investment related strategies and questions anytime!</p>
                        </div>
                    </div>
                    <img src={ai} class="custom-block-image img-fluid" alt="AI Assistance" />
                </div>
            </div>
        </div>
    </div>
</section>

    )
}