import EmailForm from './EmailForm';
export default function TimelineSection(){
    return (
        <section class="timeline-section section-padding" id="section_3">
        <div class="section-overlay"></div>

        <div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h2 class="text-white mb-4">How does it work?</h2>
        </div>
        <div class="col-lg-10 col-12 mx-auto">
            <div class="timeline-container">
                <ul class="vertical-scrollable-timeline" id="vertical-scrollable-timeline">
                    <div class="list-progress">
                        <div class="inner"></div>
                    </div>
                    <li>
                        <h4 class="text-white mb-3">Compare your stock against top sector peers.</h4>
                        <p class="text-white">Add as many stocks to the search bar as you like to compare and monitor</p>
                        <div class="icon-holder">
                            <i class="bi-search"></i>
                        </div>
                    </li>
                    <li>
                        <h4 class="text-white mb-3">We do the research and build a dashboard for you</h4>
                        <p class="text-white">Say goodbye to hours of manual comparison – our platform generates a shortlist in mere seconds.
                            But the journey doesn't end there! With your shortlist in hand, you can dive into the qualitative research that sets you apart.</p>
                        <div class="icon-holder">
                            <i class="bi-laptop"></i>
                        </div>
                    </li>
                    <li>
                        <h4 class="text-white mb-3">Research at your fingertips with our AI, Scout</h4>
                        <p class="text-white">Scout will help you learn as you go!</p>
                        <div class="icon-holder">
                            <i class="bi-activity"></i>
                        </div>
                    </li>
                    <li>
                        <h4 class="text-white mb-3">Dashboard Ready</h4>
                        <p class="text-white">Customize your preferences, explore preferred metrics, and tailor your selection with ease to generate your shortlist full of potential!</p>
                        <div class="icon-holder">
                            <i class="bi-back"></i>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 text-center mt-5"></div>
    </div>
</div>


        </section>

    )
}