// TOSPage.jsx
import React from "react";

function TOSPage() {
  return (
    <div>
      <h1>Terms and Conditions for SectorScout</h1>
      <p>
        <pre
          className="leading-relaxed whitespace-pre-wrap"
          style={{ fontFamily: "sans-serif" }}
        >
          {`Effective Date: May 19, 2024

Welcome to SectorScout! By accessing or using our website, https://www.sectorscout.ai/ (the "Site"), you agree to comply with and be bound by the following terms and conditions (the "Terms"). Please review these Terms carefully. If you do not agree to these Terms, you should not use our Site.

1. Services Provided

SectorScout is a stock education and research platform. We provide educational content and research services to help you reduce the time spent researching stocks from days to minutes. We do not recommend or suggest buying or selling any stocks.

2. User Data

We collect the following personal information from our users:

Name
Email address
Payment information
We also collect non-personal data through web cookies. For more information, please refer to our Privacy Policy: Privacy Policy.

3. Ownership and Use of Content

All content provided on the Site is for educational and research purposes only. You may not copy, distribute, or use any content from the Site without our prior written consent.

4. Governing Law

These Terms are governed by and construed in accordance with the laws of France. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of France.

5. Updates to Terms

We may update these Terms from time to time. If we make any changes, we will notify you by email.

6. Contact Us

If you have any questions about these Terms, please contact us at sectorscout.ai@gmail.com.

Thank you for using SectorScout.

SectorScout`}
        </pre>

  </p>
    </div>
  );
}

export default TOSPage;
