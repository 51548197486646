// PrivacyPolicy.jsx
import React from "react";

function PrivacyPolicy() {
  return (
    <div>
      <h1>Privacy Policy for SectorScout</h1>

        <pre
          className="leading-relaxed whitespace-pre-wrap"
          style={{ fontFamily: "sans-serif" }}
        >
          {`Effective Date: May 19, 2024

Welcome to SectorScout! We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website, https://www.sectorscout.ai/ (the "Site").

1. Information We Collect

Personal Information:

Name
Email address
Payment information
Non-Personal Information:

Web cookies
2. Purpose of Data Collection

We collect your information for the purpose of processing orders and providing you with the services you have requested.

3. Data Sharing

We do not share your personal information with any other parties.

4. Children's Privacy

We do not knowingly collect any data from children. If we become aware that we have inadvertently received personal information from a child, we will delete such information from our records.

5. Updates to This Privacy Policy

We may update this Privacy Policy from time to time. If we make any changes, we will notify you by email.

6. Contact Us

If you have any questions about this Privacy Policy, please contact us at sectorscout.ai@gmail.com.

Thank you for choosing SectorScout.

SectorScout`}
        </pre>

      
    </div>
  );
}

export default PrivacyPolicy;
